import { Entity, ready } from '../lib/jet/index.js'

const EnableDB = new Entity({
  name: 'enabledb',
  selector: 'body',

  on: {
    initialize () {
      console.log('WORKS')
    }
  }
})

ready(() => EnableDB.initialize())